import Vue from 'vue';
const PDF = {
  // fontsetup : {
  //   // download default Roboto font from cdnjs.com
  //   Roboto: {
  //     normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
  //     bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
  //     italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
  //     bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  //   },
  //   // Kanit Font
  //   Sarabun: { // 3. set Kanit font
  //     normal: 'Sarabun-Regular.ttf',
  //     bold: 'Sarabun-Medium.ttf',
  //     italics: 'Sarabun-Italic.ttf',
  //     bolditalics: 'Sarabun-MediumItalic.ttf'
  //   },
  // },
  slipsize : {
    width: 670,
    height: 150
  },
  docDefinition: {

    /**
     * ##########################################################
     * ##########################################################
     * ##########################################################
     */

    /**
     *
     *     ####   ####  #    # ###### #  ####
     *    #    # #    # ##   # #      # #    #
     *    #      #    # # #  # #####  # #
     *    #      #    # #  # # #      # #  ###
     *    #    # #    # #   ## #      # #    #
     *     ####   ####  #    # #      #  ####
     *
     *    The pdf document is configured with
     *    the following default object properties.
     *
     */
    // Document Meta Data (Visible in document properties)
    info: {
      title: "Title of Document",
      author: "Author Name",
      subject: "Subject of Document",
      keywords: "Keywords for Document",
      creator: "XYZ Solutions Inc.",
      producer: "pdfmake-0.2.6",
      creationDate: new Date(),
      modDate: new Date()
    },

    // Document Compression (May substantially reduce size of PDF file!)
    compress: false,

    // Document Version of PDF (Required for and specifies encryption type!)
    version: "1.7",
    // Document User Password (Required to activate encryption!)
    userPassword: "",
    // Document Owner Password (Required to activate permissions!)
    ownerPassword: "123",

    // Document Permissions
    permissions: {
      printing: "highResolution",
      modifying: false,
      copying: false,
      annotating: false,
      fillingForms: false,
      contentAccessibility: false,
      documentAssembly: false,
      exttractingPages: false
    },

    // Document Page Settings (Affects all pages!)
    pageSize: "LETTER",
    pageOrientation: "portrait",
    // left, top, right, bottom
    pageMargins: [54, 54, 54, 72],

    // Background Layer (Prints on all pages!)
    background: {
      // Document Border
      canvas: [
        {
          type: "rect",
          x: 36,  y: 36,
          w: 540, h: 702,
          r: 3,
          lineColor: "#212121"
        }
      ]
    },

    // Watermark (Prints on all pages!)
    watermark: {
      text: "Watermark",
      fontSize: 72,
      color: "#6d4c41",
      opacity: 0.075,
      angle: -55,
      normal: true,
      bold: true,
      italics: false,
      bolditalics: false
    },

    // Document Default Styles (Takes precendence until locally overwritten!)
//       font: 'Sarabun',

    defaultStyle: {
      font: 'Tahoma',
      fontSize: 8,
      color: "#404040",
      normal: true,
      bold: false,
      italics: false,
      bolditalics: false
    },

    // Document Custom Styles (Optionally applied locally!)
    styles: {
      "text-left": {
        alignment: "left"
      },
      "text-center": {
        alignment: "center"
      },
      "text-right": {
        alignment: "right"
      },
    },

    /**
     * ##########################################################
     * ##########################################################
     * ##########################################################
     */

    /**
     *
     *    #    # ######   ##   #####  ###### #####
     *    #    # #       #  #  #    # #      #    #
     *    ###### #####  #    # #    # #####  #    #
     *    #    # #      ###### #    # #      #####
     *    #    # #      #    # #    # #      #   #
     *    #    # ###### #    # #####  ###### #    #
     *
     *    The pdf document header is stored
     *    in the header object property.
     *
     */
    header: {
      columns: [
        // STACK 1
        {
          stack: [
            {
              text: "header-left",
              // left, top, right, bottom
              margin: [41, 27, 0, 0],
              font: "Roboto",
              fontSize: 7.5,
              color: "#404040",
              normal: true,
              bold: true,
              italics: false,
              bolditalics: false
            }
          ],
          width: "*",
          style: "text-left"
        },
        // STACK 2
        {
          stack: [
            {
              text: "header-center",
              // left, top, right, bottom
              margin: [0, 27, 0, 0],
              font: "Roboto",
              fontSize: 7.5,
              color: "#404040",
              normal: true,
              bold: true,
              italics: false,
              bolditalics: false
            }
          ],
          width: "*",
          style: "text-center"
        },
        // STACK 3
        {
          stack: [
            {
              text: "header-right",
              // left, top, right, bottom
              margin: [0, 27, 41, 0],
              font: "Roboto",
              fontSize: 7.5,
              color: "#404040",
              normal: true,
              bold: true,
              italics: false,
              bolditalics: false
            }
          ],
          width: "*",
          style: "text-right"
        }
      ]
    },

    /**
     * ##########################################################
     * ##########################################################
     * ##########################################################
     */

    /**
     *
     *     ####   ####  #    # ##### ###### #    # #####
     *    #    # #    # ##   #   #   #      ##   #   #
     *    #      #    # # #  #   #   #####  # #  #   #
     *    #      #    # #  # #   #   #      #  # #   #
     *    #    # #    # #   ##   #   #      #   ##   #
     *     ####   ####  #    #   #   ###### #    #   #
     *
     *    The pdf document content is stored
     *    in the content object property.
     *
     */

    content: [
      // Simple text example
      "This is a simple text line.",
      // Rich text example
      {
        text: [
          // Simple text part...
          "This is a rich-text line with text ",
          // Rich text part...
          {
            text: "formatted in bold",
            // left, top, right, bottom
            margin: [0, 0, 0, 0],
            font: "Roboto",
            fontSize: 12,
            color: "#404040",
            normal: true,
            bold: true,
            italics: false,
            bolditalics: false
          },
          // Another Simple text part...
          ".",
          // Add more parts from here on...
        ],
      },

    ], // End content here.

    /**
     * ##########################################################
     * ##########################################################
     * ##########################################################
     */

    /**
     *
     *    ######  ####   ####  ##### ###### #####
     *    #      #    # #    #   #   #      #    #
     *    #####  #    # #    #   #   #####  #    #
     *    #      #    # #    #   #   #      #####
     *    #      #    # #    #   #   #      #   #
     *    #       ####   ####    #   ###### #    #
     *
     *    The pdf document footer is returned
     *    from the footer object property.
     *    In this case, footer is a function which
     *    returns dynamic data including current year,
     *    page nubmer & page count.
     *
     */
    footer: function(currentPage, pageCount) {

      return {

        columns: [
          // STACK 1
          {
            stack: [
              {
                text: `Form F-1 © ${new Date().getFullYear()} - XYZ Solutions Inc.`,
                // left, top, right, bottom
                margin: [41, 21, 0, 0],
                font: "Roboto",
                fontSize: 7.5,
                color: "#404040",
                normal: true,
                bold: true,
                italics: false,
                bolditalics: false
              }
            ],
            width: "*",
            style: "text-left"
          },
          // STACK 2
          {
            stack: [
              {
                text: "footer-center",
                // left, top, right, bottom
                margin: [0, 21, 0, 0],
                font: "Roboto",
                fontSize: 7.5,
                color: "#404040",
                normal: true,
                bold: true,
                italics: false,
                bolditalics: false
              }
            ],
            width: "*",
            style: "text-center"
          },
          // STACK 3
          {
            stack: [
              {
                text: "PAGE " + currentPage.toString() + " of " + pageCount.toString(),
                // left, top, right, bottom
                margin: [0, 21, 41, 0],
                font: "Roboto",
                fontSize: 7.5,
                color: "#404040",
                normal: true,
                bold: true,
                italics: false,
                bolditalics: false
              }
            ],
            width: "*",
            style: "text-right"
          }
        ]
      }
    }
  }
}

//this is to help Webstorm with autocomplete
Vue.prototype.$PDF = PDF;

export default ({ app }, inject) => {
  inject('PDF', PDF);
}
