export const state = () => ({
  salesitems: []
});

export const mutations = {
  add(state, item) {
    // console.log('item-->',item)
    state.salesitems.push(item);
  },
  remove(state, { todo }) {
    state.salesitems.splice(state.salesitems.indexOf(todo), 1);
  },
  toggle(state, todo) {
    todo.done = !todo.done;
  }
};
