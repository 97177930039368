

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","messages":{"en":{"welcome":"Welcome","Databases":"Databases","ขายสินค้า":"Sales","user":"user name ","bill":"ID","billdate":"Date","billname":"Name","link":["Welcome","Inspiration"],"loginform":{"user":"User Name","password":"Password"},"quote":"First, solve the problem. Then, write the code."},"th":{"welcome":"สวัสดี","Databases":"ฐานข้อมูล","ขายสินค้า":"ขายสินค้า","user":"ชื่อผู้ใข้งาน","bill":"รหัส","billdate":"วันที่","billname":"ชื่อ","link":["ฐานข้อมูล","แรงบันดาลใจ"],"loginform":{"user":"ผู้ใช้ระบบ","password":"รหัสผ่าน"},"quote":"คิดก่อนเขียนโคด"}}},
  vueI18nLoader: false,
  locales: ["en","th"],
  defaultLocale: "th",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: null,
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en"},{"code":"th"}],
  localeCodes: ["en","th"],
}

export const localeMessages = {}
