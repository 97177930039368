export const Button = () => import('../..\\components\\Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const ButtonForm = () => import('../..\\components\\ButtonForm.vue' /* webpackChunkName: "components/button-form" */).then(c => wrapFunctional(c.default || c))
export const Calendar = () => import('../..\\components\\Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../..\\components\\Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const Col = () => import('../..\\components\\Col.vue' /* webpackChunkName: "components/col" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../..\\components\\Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const ContainerDynamic = () => import('../..\\components\\Container_dynamic.vue' /* webpackChunkName: "components/container-dynamic" */).then(c => wrapFunctional(c.default || c))
export const DateField = () => import('../..\\components\\DateField.vue' /* webpackChunkName: "components/date-field" */).then(c => wrapFunctional(c.default || c))
export const DateFieldBak = () => import('../..\\components\\DateField_bak.vue' /* webpackChunkName: "components/date-field-bak" */).then(c => wrapFunctional(c.default || c))
export const Divider = () => import('../..\\components\\Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c))
export const Img = () => import('../..\\components\\Img.vue' /* webpackChunkName: "components/img" */).then(c => wrapFunctional(c.default || c))
export const Keypad = () => import('../..\\components\\Keypad.vue' /* webpackChunkName: "components/keypad" */).then(c => wrapFunctional(c.default || c))
export const MainMenu = () => import('../..\\components\\MainMenu.vue' /* webpackChunkName: "components/main-menu" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../..\\components\\Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ModalForm = () => import('../..\\components\\ModalForm.vue' /* webpackChunkName: "components/modal-form" */).then(c => wrapFunctional(c.default || c))
export const NumField = () => import('../..\\components\\NumField.vue' /* webpackChunkName: "components/num-field" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../..\\components\\NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const RadioField = () => import('../..\\components\\RadioField.vue' /* webpackChunkName: "components/radio-field" */).then(c => wrapFunctional(c.default || c))
export const Row = () => import('../..\\components\\Row.vue' /* webpackChunkName: "components/row" */).then(c => wrapFunctional(c.default || c))
export const Select2Field = () => import('../..\\components\\Select2Field.vue' /* webpackChunkName: "components/select2-field" */).then(c => wrapFunctional(c.default || c))
export const SelectField = () => import('../..\\components\\SelectField.vue' /* webpackChunkName: "components/select-field" */).then(c => wrapFunctional(c.default || c))
export const Shotcutmenu = () => import('../..\\components\\Shotcutmenu.vue' /* webpackChunkName: "components/shotcutmenu" */).then(c => wrapFunctional(c.default || c))
export const Table = () => import('../..\\components\\Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const TableForm = () => import('../..\\components\\TableForm.vue' /* webpackChunkName: "components/table-form" */).then(c => wrapFunctional(c.default || c))
export const Test = () => import('../..\\components\\test.vue' /* webpackChunkName: "components/test" */).then(c => wrapFunctional(c.default || c))
export const TextareaField = () => import('../..\\components\\TextareaField.vue' /* webpackChunkName: "components/textarea-field" */).then(c => wrapFunctional(c.default || c))
export const TextField = () => import('../..\\components\\TextField.vue' /* webpackChunkName: "components/text-field" */).then(c => wrapFunctional(c.default || c))
export const TextFieldbak = () => import('../..\\components\\TextFieldbak.vue' /* webpackChunkName: "components/text-fieldbak" */).then(c => wrapFunctional(c.default || c))
export const TimeField = () => import('../..\\components\\TimeField.vue' /* webpackChunkName: "components/time-field" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../..\\components\\Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../..\\components\\VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const Forminput = () => import('../..\\components\\Forminput\\index.vue' /* webpackChunkName: "components/forminput" */).then(c => wrapFunctional(c.default || c))
export const Home = () => import('../..\\components\\Home\\index.vue' /* webpackChunkName: "components/home" */).then(c => wrapFunctional(c.default || c))
export const PosSalesTable = () => import('../..\\components\\Pos\\Sales_table.vue' /* webpackChunkName: "components/pos-sales-table" */).then(c => wrapFunctional(c.default || c))
export const AuthLogin = () => import('../..\\components\\auth\\login\\index.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const DbtpCategory = () => import('../..\\components\\dbtp\\Category\\index.vue' /* webpackChunkName: "components/dbtp-category" */).then(c => wrapFunctional(c.default || c))
export const DbtpBrand = () => import('../..\\components\\dbtp\\Brand\\index.vue' /* webpackChunkName: "components/dbtp-brand" */).then(c => wrapFunctional(c.default || c))
export const DbtpExpen = () => import('../..\\components\\dbtp\\Expen\\index.vue' /* webpackChunkName: "components/dbtp-expen" */).then(c => wrapFunctional(c.default || c))
export const DbtpUm = () => import('../..\\components\\dbtp\\Um\\index.vue' /* webpackChunkName: "components/dbtp-um" */).then(c => wrapFunctional(c.default || c))
export const DbtpSupplier = () => import('../..\\components\\dbtp\\Supplier\\index.vue' /* webpackChunkName: "components/dbtp-supplier" */).then(c => wrapFunctional(c.default || c))
export const DbtpProducts = () => import('../..\\components\\dbtp\\Products\\index.vue' /* webpackChunkName: "components/dbtp-products" */).then(c => wrapFunctional(c.default || c))
export const DbtrCashmove = () => import('../..\\components\\dbtr\\Cashmove\\index.vue' /* webpackChunkName: "components/dbtr-cashmove" */).then(c => wrapFunctional(c.default || c))
export const DbtrExpen = () => import('../..\\components\\dbtr\\Expen\\index.vue' /* webpackChunkName: "components/dbtr-expen" */).then(c => wrapFunctional(c.default || c))
export const DbtrInstockBak = () => import('../..\\components\\dbtr\\Instock\\bak.vue' /* webpackChunkName: "components/dbtr-instock-bak" */).then(c => wrapFunctional(c.default || c))
export const DbtrInstock = () => import('../..\\components\\dbtr\\Instock\\index.vue' /* webpackChunkName: "components/dbtr-instock" */).then(c => wrapFunctional(c.default || c))
export const DbtpDepartment = () => import('../..\\components\\dbtp\\Department\\index.vue' /* webpackChunkName: "components/dbtp-department" */).then(c => wrapFunctional(c.default || c))
export const DbtrPo = () => import('../..\\components\\dbtr\\Po\\index.vue' /* webpackChunkName: "components/dbtr-po" */).then(c => wrapFunctional(c.default || c))
export const DbtrPos = () => import('../..\\components\\dbtr\\Pos\\index.vue' /* webpackChunkName: "components/dbtr-pos" */).then(c => wrapFunctional(c.default || c))
export const HomeUsers = () => import('../..\\components\\Home\\Users\\index.vue' /* webpackChunkName: "components/home-users" */).then(c => wrapFunctional(c.default || c))
export const PosFindForm = () => import('../..\\components\\Pos\\FindForm\\index.vue' /* webpackChunkName: "components/pos-find-form" */).then(c => wrapFunctional(c.default || c))
export const PosHotkeybtnBak = () => import('../..\\components\\Pos\\Hotkeybtn\\bak.vue' /* webpackChunkName: "components/pos-hotkeybtn-bak" */).then(c => wrapFunctional(c.default || c))
export const PosHotkeybtn = () => import('../..\\components\\Pos\\Hotkeybtn\\index.vue' /* webpackChunkName: "components/pos-hotkeybtn" */).then(c => wrapFunctional(c.default || c))
export const PosListItem = () => import('../..\\components\\Pos\\ListItem\\index.vue' /* webpackChunkName: "components/pos-list-item" */).then(c => wrapFunctional(c.default || c))
export const PosPrintForm = () => import('../..\\components\\Pos\\PrintForm\\index.vue' /* webpackChunkName: "components/pos-print-form" */).then(c => wrapFunctional(c.default || c))
export const PosProdFindFormBak = () => import('../..\\components\\Pos\\ProdFindForm\\bak.vue' /* webpackChunkName: "components/pos-prod-find-form-bak" */).then(c => wrapFunctional(c.default || c))
export const PosProdFindForm = () => import('../..\\components\\Pos\\ProdFindForm\\index.vue' /* webpackChunkName: "components/pos-prod-find-form" */).then(c => wrapFunctional(c.default || c))
export const PosPicbutton = () => import('../..\\components\\Pos\\Picbutton\\index.vue' /* webpackChunkName: "components/pos-picbutton" */).then(c => wrapFunctional(c.default || c))
export const PosProListbtn = () => import('../..\\components\\Pos\\ProListbtn\\index.vue' /* webpackChunkName: "components/pos-pro-listbtn" */).then(c => wrapFunctional(c.default || c))
export const PosSidebar = () => import('../..\\components\\Pos\\Sidebar\\index.vue' /* webpackChunkName: "components/pos-sidebar" */).then(c => wrapFunctional(c.default || c))
export const PosSidebarRight = () => import('../..\\components\\Pos\\SidebarRight\\index.vue' /* webpackChunkName: "components/pos-sidebar-right" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
