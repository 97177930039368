
export default {
  data() {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      title: this.$Func.appTitle ,
      user : '' ,
      menuuser : [
        // {
        //   icon: 'mdi-apps',
        //   title: 'Welcome',
        //   to: '/',
        // },
        // {
        //   icon: 'mdi-chart-bubble',
        //   title: 'Inspire',
        //   to: '/inspire',
        // },
      ],
      menu_current : '' ,
      miniVariant: false,
      right: true,
      rightDrawer: false,
    }
  },
  async fetch(){
    this.user = this.$auth.user
    // console.log( this.user.name )
    this.menuuser = await this.$axios.$get('mainmenu/getAll') ;
    this.menu_current = this.$Func.getlast_menuname()
  },
  methods: {
    async logout() {
      await this.$auth.logout()
      this.$router.push('/login')
    },
    menudetail(item) {
      this.drawer = false ;
      this.menu_current = item.menu_name
      localStorage.setItem('365XtraPOS_currentmenu', JSON.stringify( item ) )
    }
    // switchLang(locale ) {
    //   if (locale === this.$store.state.i18n.locale) {
    //     return;
    //   }
    //   // update store info
    //   this.$store.commit('i18n/i18nSetLocale', locale);

    //   // fetch new locale file
    //   import(`../lang/${locale}`).then(module => {
    //     // update i18n's locale instance
    //     this.$i18n.locale = locale;
    //     // set new messages from new locale file
    //     this.$i18n.setLocaleMessage(locale, module.default);
    //     // update url to point to new path, without reloading the page
    //     window.history.replaceState('', '', this.switchLocalePath(locale));
    // });
  // }
  },
  computed: {
    link:function(e) {
      return this.$t(e)
      // return this.$t('link')
    }
  }
}
