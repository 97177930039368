export default function (context) {
   const curpath = ( context.route.fullPath )   
   // console.log( context.store.state.auth.loggedIn, curpath  )

   if (!context.store.state.auth.loggedIn) {
      if ( curpath !== '/auth/login' 
            && curpath !== '/auth/login/')   {
         return context.redirect("/auth/login");
      }
   }
 }