// export default (context, inject) => {
//     const hello = (msg) => console.log(`Hello ${msg}!`)
//     // Inject $hello(msg) in Vue, context and store.
//     inject('hello', hello)
//     // For Nuxt <= 2.12, also add 👇
//     context.$hello = hello
// }

import Vue from 'vue';

const Func = {
  appTitle: '365XtraPOS V. 0.07',
  vat : 0.07 ,
  fontsize : 7.5 ,
  text_no_save : {
    validate : {
      icon: "info",
      title : '365XtraPOS' ,
      text : 'โปรดเช็ครายการอีกครั้ง มีรายการที่ไม่บันทึก !!!',
      allowOutsideClick: false,
      allowEscapeKey: false,
    },
    error : {
      icon: "error",
      title : '365XtraPOS' ,
      text : 'โปรดเช็ครายการอีกครั้ง ระบบไม่สามารถบันทึกได้ !!!',
      allowOutsideClick: false,
      allowEscapeKey: false,
    } ,
    success : {
      icon: "success",
      title : '365XtraPOS' ,
      text : 'ระบบบันทึกรายการเรียบร้อยแล้ว',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }
  },

  pos_slip_head : [
    {
      text : [
        {
          text: ":#HEAD1",
          // left, top, right, bottom
          margin: [0, 0, 0, 0],
          // font: "Sarabun",
          // fontSize: ()=>{
          //   return this.fontsize
          // },
          color: "#404040",
          normal: true,
          bold: true,
          italics: false,
          bolditalics: false
        }
      ],
      width: "*",
      style: "text-center"
    },
    {
      text : [
        {
          text: ":#HEAD2",
          // left, top, right, bottom
          margin: [0, 0, 0, 0],
          // font: "Sarabun",
          // fontSize: ()=>{
          //   return this.fontsize
          // },
          color: "#404040",
          normal: true,
          bold: true,
          italics: false,
          bolditalics: false
        }
      ],
      width: "*",
      style: "text-center"
    },
    {
      text : [
        {
          text: ":#HEAD3",
          // left, top, right, bottom
          margin: [0, 0, 0, 0],
          // font: "Sarabun",
          // fontSize: this.fontsize_slip(),
          color: "#404040",
          normal: true,
          bold: true,
          italics: false,
          bolditalics: false
        }
      ],
      width: "*",
      style: "text-center"
    },
    {
      text: [
        {
          text: "-----------------------------",
        },
      ], width:"*",style:"text-center"
    },
    {
      columns: [
        {
          stack:[
            {
            text: "B: " + ":#s1"
            }
          ],
          width: "*",
          style: "text-left"
        },
        {
          stack:[
            {
            text: " " + ":#s2" +" ",
            margin: [0, 0, 5, 0],
            }
          ],
          width: "*",
          style: "text-right"
        },
      ],
    },
    {
      columns : [
        {
          stack:[
            {
            text: "U: " + ":#s3"
            }
          ],
          width: "*",
          style: "text-left"
        },
      ]
    },
    {
      text: [
        {
          text: "-----------------------------",
        },
      ], width:"*",style:"text-center"
    },
  ],
  pos_slip_body : [
    {
      text: [
        {
          text: ":#lno.  :#b1",
        },
      ],
      width: "5",
      style: "text-left"
    },
    {
      columns : [
        {
          stack:[
            {
              text: [
                {
                  text: ":#b2",
                  // left, top, right, bottom
                  margin: [5, 0,0, 0],
                },
              ],
              width: "5",
              style: "text-left"
            }
          ]
        },
        {
          stack:[
            {
              text: [
                {
                  text: ":#b3",
                  // left, top, right, bottom
                  margin: [0, 0,5, 0],
                },
              ],
              width: "5",
              style: "text-right"
            }
          ]
        },
      ]
    }
  ],
  pos_slip_footer : [
    {
      text: [
        {
          text: "-----------------------------",
        },
      ], width:"*",style:"text-center"
    },
    {
      columns:[
        {
          stack: [
            {
              text: "ยอดรวม",
              // left, top, right, bottom
            }
          ],
          width: "*",
          style: "text-left"
        } ,
        {
          stack: [
            {
              text: ":#total",
              // left, top, right, bottom
            }
          ],
          width: "*",
          style: "text-right"
        }
      ]
    },

    {
      columns:[
        {
          stack: [
            {
              text: "รับเงิน: :#rev",
              // left, top, right, bottom
            }
          ],
          width: "*",
          style: "text-left"
        } ,
        {
          stack: [
            {
              text: "ทอน :#change",
              // left, top, right, bottom
            }
          ],
          width: "*",
          style: "text-right"
        }
      ]
    },
    {
      columns:[
        {
          stack: [
            {
              text: "จำนวนสินค้า: :#itemqty",
              // left, top, right, bottom
            }
          ],
          width: "*",
          style: "text-left"
        } ,
        {
          stack: [
            {
              text: "",
              // left, top, right, bottom
            }
          ],
          width: "*",
          style: "text-right"
        }
      ]
    },
    {
      text: [
        {
          text: "-----------------------------",
        },
      ], width:"*",style:"text-center"
    },
    // Footer bill
    {
      text: [
        {
          text: ":#e1",
          // left, top, right, bottom
          margin: [0, 0, 0, 0],
          // font: "Sarabun",
          // fontSize: 12,
          color: "#404040",
          normal: true,
          bold: true,
          italics: false,
          bolditalics: false
        },
      ],
      width: "*",
      style: "text-center"
    },
    {
      text: [
        {
          text: ":#e2",
          // left, top, right, bottom
          margin: [0, 0, 0, 0],
          // font: "Sarabun",
          // fontSize: 12,
          color: "#404040",
          normal: true,
          bold: true,
          italics: false,
          bolditalics: false
        },
      ],
      width: "*",
      style: "text-center"
    },
    {
      text: [
        {
          text: ":#e3",
          // left, top, right, bottom
          margin: [0, 0, 0, 0],
          // font: "Sarabun",
          // fontSize: 12,
          color: "#404040",
          normal: true,
          bold: true,
          italics: false,
          bolditalics: false
        },
      ],
      width: "*",
      style: "text-center"
    },
    {
      text: [
        {
          text: " . ",
          // left, top, right, bottom
          margin: [0, 0, 0, 0],
          // font: "Sarabun",
          // fontSize: 12,
          color: "#404040",
          normal: true,
          bold: true,
          italics: false,
          bolditalics: false
        },
      ],
      width: "*",
      style: "text-center"
    },
  ],

  pos_msg:{
    product_not_found : {
      icon: "info",
      title : '365XtraPOS' ,
      text : 'ไม่พบรหัสสินค้า !!!',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }
  },
  currentdate: new Date(),
  today: `${new Date().getDate()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')
    }-${new Date().getFullYear()}`,
  engtoday: `${new Date().getFullYear()}-${ (new Date().getMonth() + 1).toString().padStart(2, '0')
  }-${new Date().getDate()}`,

  bathbtn : [
    {num:'1',icon:'mdi-bitcoin'},
    {num:'2',icon:'mdi-bitcoin'},
    {num:'5',icon:'mdi-bitcoin'},
    {num:'10',icon:'mdi-bitcoin'},
    {num:'20',icon:'mdi-cash'},
    {num:'50',icon:'mdi-cash'},
    {num:'100',icon:'mdi-cash'},
    {num:'500',icon:'mdi-cash'},
    {num:'1000',icon:'mdi-cash'},
  ] ,
  cursor_row(e){
    return {
      lno : e ,
      itemid : '' ,
      itemname : '' ,
      qty : '' ,
      unitprice : '' ,
      total : ''
    }
  } ,
  cussor_table( arr_table ){
    this.cursor_row(1)
    let table = []
    if (!arr_table) {
      table = []
    }else{
      table = arr_table
    }
    let e = 1
    for (let index = 0; index < 50; index++) {
      table.push(this.cursor_row(e))
      // table.push({
      //   lno : e ,
      //   itemid : '' ,
      //   itemname : '' ,
      //   qty : '' ,
      //   unitprice : '' ,
      //   total : ''
      // })
      e++
    }
    return table
  },
  thaiDateSqlFormat(datetime){
    // if (!datetime) return ''
    // console.log( datetime )
    let res = new Date(datetime.substring(0,10))
    // const [year,month,day ] = res.split('-')
    let Year = res.getFullYear()
    let Month = (res.getMonth()+1).toString().padStart(2,'0')
    let date = res.getDate().toString().padStart(2,'0')
    let Hou = res.getHours()
    let min = res.getMinutes()
    let re =  date  + '-' + Month +'-'+ Year
    return re
  },
  engFormat(datetime){
    if (!datetime) return ''
    let res = new Date(datetime)
    // const [year,month,day ] = res.split('-')
    let Year = res.getFullYear()
    let Month = (res.getMonth()+1).toString().padStart(2,'0')
    let date = res.getDate().toString().padStart(2,'0')
    let Hou = ('00'+res.getHours()).toString().slice(-2)
    let min = ('00'+res.getMinutes()).toString().slice(-2)
    let re =  Year + '-'  + Month +'-'+ date
    return re
  },

  thaiFormat(datetime){
    if (!datetime) return ''
    let res = new Date(datetime)
    // const [year,month,day ] = res.split('-')
    let Year = res.getFullYear()
    let Month = (res.getMonth()+1).toString().padStart(2,'0')
    let date = res.getDate().toString().padStart(2,'0')
    let Hou = ('00'+res.getHours()).toString().slice(-2)
    let min = ('00'+res.getMinutes()).toString().slice(-2)
    let re =  date  + '-' + Month +'-'+ Year + ' '+Hou+':'+min
    return re
    // console.log( datetime )

    // let time = datetime.toString().substring(-10)
    // let tdate= `${new Date(datetime).getDate()}-${new Date(datetime).getMonth() + 1
    // }-${new Date(datetime).getFullYear()}`
    // return tdate + '' +time
  },
  numFormat(num) {
    return (num).toLocaleString('th-TH',{minimumFractionDigits:2,maximumFractionDigits:2})
  },
  msg( word ,icon){
    const data =
      {
        icon: icon,
        title : '365XtraPOS' ,
        text :  word ,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }
    return data
  },
  msghtml( word ,icon){
    const data =
      {
        icon: icon,
        title : '365XtraPOS' ,
        html :  word ,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }
    return data
  },
  getlast_user() {
    return localStorage.getItem('365xtrapos_olduser') ;
  },
  getlast_menu(){
    const menu =  JSON.parse( localStorage.getItem('365XtraPOS_currentmenu') ) || '[]'
    return menu
  } ,
  getlast_menuname(){
    const menu =  JSON.parse( localStorage.getItem('365XtraPOS_currentmenu') ) || '[]'
    return menu.menu_name
  } ,
  getlast_pos(){
    const menu =  JSON.parse( localStorage.getItem('365xtrapos_salesitem') ) || []
    return menu
  } ,
  SaveLS(data)
  {
      var a = [];
      // Parse the serialized data back into an aray of objects
      a = JSON.parse(localStorage.getItem('365xtrapos_salesitem')) || [];
      // Push the new data (whether it be an object or anything else) onto the array
      a.push(data);
      // Alert the array value
      // alert(a);  // Should be something like [Object array]
      // Re-serialize the array back into a string and store it in localStorage
      localStorage.setItem('365xtrapos_salesitem', JSON.stringify(a));
  },
  PosdelLS(item)
  {
    var a = [];
    // Parse the serialized data back into an aray of objects
    a = JSON.parse(localStorage.getItem('365xtrapos_salesitem')) || '[]';
    if ( a.length > 0) {
      a[item].del = 1
    }
    localStorage.setItem('365xtrapos_salesitem', JSON.stringify(a));
  },
  ClearLS()
  {
      localStorage.setItem('365xtrapos_salesitem', '[]');
  },

  GDcalColor( color1,ratio ) {
    var color1 = 'FF0000';
    var color2 = '00FF00';
    var ratio = 0.5;
    var hex = ((x) => {
      x = x.toString(16);
      return (x.length == 1) ? '0' + x : x;
    })
    var r = Math.ceil(parseInt(color1.substring(0,2), 16) * ratio + parseInt(color2.substring(0,2), 16) * (1-ratio));
    var g = Math.ceil(parseInt(color1.substring(2,4), 16) * ratio + parseInt(color2.substring(2,4), 16) * (1-ratio));
    var b = Math.ceil(parseInt(color1.substring(4,6), 16) * ratio + parseInt(color2.substring(4,6), 16) * (1-ratio));
    var middle = hex(r) + hex(g) + hex(b);
    return middle
  },


  calColor( color1,ratio ) {
    var hex = ((x) => {
      x = x.toString(16);
      return (x.length == 1) ? '0' + x : x;
    })
    var r = Math.ceil(parseInt(color1.substring(0,2), 16) * (1-ratio) )
    var g = Math.ceil(parseInt(color1.substring(2,4), 16) * (1-ratio) )
    var b = Math.ceil(parseInt(color1.substring(4,6), 16) * (1-ratio) )
    var middle = hex(r) + hex(g) + hex(b);
    return '#'+middle
  },
  checkValidate(chk,data){
    let iRes = true
    Object.entries(data).forEach(entry => {
        const [key, value] = entry;
        if ( chk.includes( key ) ) {
          // console.log( key ,value )
          if ( value == '' ) {
            iRes = false
          }
        }
    });
    return iRes
  },
  show_shotcut(e) {
    e.preventDefault()
    this.showMenu = false
    this.x = e.clientX
    this.y = e.clientY
    this.$nextTick(() => {
      this.showMenu = true
    })
  },
  isNum( v ) {
    return /\d/.test(v)
  },
  formatAsCurrency (value, dec) {
      let val = value
      if ( Func.isNum( val ) ) {
        val = parseFloat( value )
      }
      // console.log('$Func '+( val ).toLocaleString('th-TH',{minimumFractionDigits:2,maximumFractionDigits:2}) )
      dec = dec || 0
      if (val === null) {
        return 0
      }
      // return '' + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
      return ''+  (val).toLocaleString('th-TH',{minimumFractionDigits:2,maximumFractionDigits:2})
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
  }
}
//this is to help Webstorm with autocomplete
Vue.prototype.$Func = Func;

export default ({ app }, inject) => {
  inject('Func', Func);
}
