import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7a1178fe = () => interopDefault(import('..\\pages\\auth\\index.vue' /* webpackChunkName: "pages/auth/index" */))
const _28aaad60 = () => interopDefault(import('..\\pages\\bak.vue' /* webpackChunkName: "pages/bak" */))
const _4d30f748 = () => interopDefault(import('..\\pages\\Db\\index.vue' /* webpackChunkName: "pages/Db/index" */))
const _3e9792dc = () => interopDefault(import('..\\pages\\dd.vue' /* webpackChunkName: "pages/dd" */))
const _d71b1bf4 = () => interopDefault(import('..\\pages\\dd2.vue' /* webpackChunkName: "pages/dd2" */))
const _2ee36fb4 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _7e48cf2e = () => interopDefault(import('..\\pages\\inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _9f089570 = () => interopDefault(import('..\\pages\\Tr\\index.vue' /* webpackChunkName: "pages/Tr/index" */))
const _1a4b3266 = () => interopDefault(import('..\\pages\\auth\\login\\index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _2617ea85 = () => interopDefault(import('..\\pages\\Db\\Brand\\index.vue' /* webpackChunkName: "pages/Db/Brand/index" */))
const _72f5032c = () => interopDefault(import('..\\pages\\Db\\Category\\index.vue' /* webpackChunkName: "pages/Db/Category/index" */))
const _c1ecb7d4 = () => interopDefault(import('..\\pages\\Db\\Department\\index.vue' /* webpackChunkName: "pages/Db/Department/index" */))
const _0c460d26 = () => interopDefault(import('..\\pages\\Db\\Expen\\index.vue' /* webpackChunkName: "pages/Db/Expen/index" */))
const _59206378 = () => interopDefault(import('..\\pages\\Db\\Products\\index.vue' /* webpackChunkName: "pages/Db/Products/index" */))
const _e27c5648 = () => interopDefault(import('..\\pages\\Db\\Supplier\\index.vue' /* webpackChunkName: "pages/Db/Supplier/index" */))
const _6018cf10 = () => interopDefault(import('..\\pages\\Db\\Um\\index.vue' /* webpackChunkName: "pages/Db/Um/index" */))
const _ac0e84d4 = () => interopDefault(import('..\\pages\\Report\\R_Adjstock\\index.vue' /* webpackChunkName: "pages/Report/R_Adjstock/index" */))
const _2cdbd3c1 = () => interopDefault(import('..\\pages\\Report\\R_Cashmove\\index.vue' /* webpackChunkName: "pages/Report/R_Cashmove/index" */))
const _60aef4fe = () => interopDefault(import('..\\pages\\Report\\R_Instock\\index.vue' /* webpackChunkName: "pages/Report/R_Instock/index" */))
const _87b6ca12 = () => interopDefault(import('..\\pages\\Report\\R_Onhand\\index.vue' /* webpackChunkName: "pages/Report/R_Onhand/index" */))
const _0cc4beb4 = () => interopDefault(import('..\\pages\\Report\\R_Po\\index.vue' /* webpackChunkName: "pages/Report/R_Po/index" */))
const _5b66ee5b = () => interopDefault(import('..\\pages\\Report\\R_Pos\\index.vue' /* webpackChunkName: "pages/Report/R_Pos/index" */))
const _43e27c87 = () => interopDefault(import('..\\pages\\Report\\R_Tr_bill\\index.vue' /* webpackChunkName: "pages/Report/R_Tr_bill/index" */))
const _1cfce654 = () => interopDefault(import('..\\pages\\Sys\\Company\\index.vue' /* webpackChunkName: "pages/Sys/Company/index" */))
const _f8f09d0a = () => interopDefault(import('..\\pages\\Sys\\Shortcut\\index.vue' /* webpackChunkName: "pages/Sys/Shortcut/index" */))
const _4319e40e = () => interopDefault(import('..\\pages\\Tr\\Adjstock\\index.vue' /* webpackChunkName: "pages/Tr/Adjstock/index" */))
const _61562424 = () => interopDefault(import('..\\pages\\Tr\\Cashmove\\index.vue' /* webpackChunkName: "pages/Tr/Cashmove/index" */))
const _61098f26 = () => interopDefault(import('..\\pages\\Tr\\Expen\\index.vue' /* webpackChunkName: "pages/Tr/Expen/index" */))
const _0170e78a = () => interopDefault(import('..\\pages\\Tr\\Instock\\index.vue' /* webpackChunkName: "pages/Tr/Instock/index" */))
const _2aae916e = () => interopDefault(import('..\\pages\\Tr\\Po\\index.vue' /* webpackChunkName: "pages/Tr/Po/index" */))
const _e882a7d0 = () => interopDefault(import('..\\pages\\Tr\\Pos\\index.vue' /* webpackChunkName: "pages/Tr/Pos/index" */))
const _3ce7811a = () => interopDefault(import('..\\pages\\auth\\login\\index_bak.vue' /* webpackChunkName: "pages/auth/login/index_bak" */))
const _279ff2b5 = () => interopDefault(import('..\\pages\\Tr\\Instock\\bak.vue' /* webpackChunkName: "pages/Tr/Instock/bak" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/APP/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _7a1178fe,
    caseSensitive: false,
    name: "auth___th"
  }, {
    path: "/bak",
    component: _28aaad60,
    caseSensitive: false,
    name: "bak___th"
  }, {
    path: "/Db",
    component: _4d30f748,
    caseSensitive: false,
    name: "Db___th"
  }, {
    path: "/dd",
    component: _3e9792dc,
    caseSensitive: false,
    name: "dd___th"
  }, {
    path: "/dd2",
    component: _d71b1bf4,
    caseSensitive: false,
    name: "dd2___th"
  }, {
    path: "/en",
    component: _2ee36fb4,
    caseSensitive: false,
    name: "index___en"
  }, {
    path: "/inspire",
    component: _7e48cf2e,
    caseSensitive: false,
    name: "inspire___th"
  }, {
    path: "/Tr",
    component: _9f089570,
    caseSensitive: false,
    name: "Tr___th"
  }, {
    path: "/auth/login",
    component: _1a4b3266,
    caseSensitive: false,
    name: "auth-login___th"
  }, {
    path: "/Db/Brand",
    component: _2617ea85,
    caseSensitive: false,
    name: "Db-Brand___th"
  }, {
    path: "/Db/Category",
    component: _72f5032c,
    caseSensitive: false,
    name: "Db-Category___th"
  }, {
    path: "/Db/Department",
    component: _c1ecb7d4,
    caseSensitive: false,
    name: "Db-Department___th"
  }, {
    path: "/Db/Expen",
    component: _0c460d26,
    caseSensitive: false,
    name: "Db-Expen___th"
  }, {
    path: "/Db/Products",
    component: _59206378,
    caseSensitive: false,
    name: "Db-Products___th"
  }, {
    path: "/Db/Supplier",
    component: _e27c5648,
    caseSensitive: false,
    name: "Db-Supplier___th"
  }, {
    path: "/Db/Um",
    component: _6018cf10,
    caseSensitive: false,
    name: "Db-Um___th"
  }, {
    path: "/en/auth",
    component: _7a1178fe,
    caseSensitive: false,
    name: "auth___en"
  }, {
    path: "/en/bak",
    component: _28aaad60,
    caseSensitive: false,
    name: "bak___en"
  }, {
    path: "/en/Db",
    component: _4d30f748,
    caseSensitive: false,
    name: "Db___en"
  }, {
    path: "/en/dd",
    component: _3e9792dc,
    caseSensitive: false,
    name: "dd___en"
  }, {
    path: "/en/dd2",
    component: _d71b1bf4,
    caseSensitive: false,
    name: "dd2___en"
  }, {
    path: "/en/inspire",
    component: _7e48cf2e,
    caseSensitive: false,
    name: "inspire___en"
  }, {
    path: "/en/Tr",
    component: _9f089570,
    caseSensitive: false,
    name: "Tr___en"
  }, {
    path: "/Report/R_Adjstock",
    component: _ac0e84d4,
    caseSensitive: false,
    name: "Report-R_Adjstock___th"
  }, {
    path: "/Report/R_Cashmove",
    component: _2cdbd3c1,
    caseSensitive: false,
    name: "Report-R_Cashmove___th"
  }, {
    path: "/Report/R_Instock",
    component: _60aef4fe,
    caseSensitive: false,
    name: "Report-R_Instock___th"
  }, {
    path: "/Report/R_Onhand",
    component: _87b6ca12,
    caseSensitive: false,
    name: "Report-R_Onhand___th"
  }, {
    path: "/Report/R_Po",
    component: _0cc4beb4,
    caseSensitive: false,
    name: "Report-R_Po___th"
  }, {
    path: "/Report/R_Pos",
    component: _5b66ee5b,
    caseSensitive: false,
    name: "Report-R_Pos___th"
  }, {
    path: "/Report/R_Tr_bill",
    component: _43e27c87,
    caseSensitive: false,
    name: "Report-R_Tr_bill___th"
  }, {
    path: "/Sys/Company",
    component: _1cfce654,
    caseSensitive: false,
    name: "Sys-Company___th"
  }, {
    path: "/Sys/Shortcut",
    component: _f8f09d0a,
    caseSensitive: false,
    name: "Sys-Shortcut___th"
  }, {
    path: "/Tr/Adjstock",
    component: _4319e40e,
    caseSensitive: false,
    name: "Tr-Adjstock___th"
  }, {
    path: "/Tr/Cashmove",
    component: _61562424,
    caseSensitive: false,
    name: "Tr-Cashmove___th"
  }, {
    path: "/Tr/Expen",
    component: _61098f26,
    caseSensitive: false,
    name: "Tr-Expen___th"
  }, {
    path: "/Tr/Instock",
    component: _0170e78a,
    caseSensitive: false,
    name: "Tr-Instock___th"
  }, {
    path: "/Tr/Po",
    component: _2aae916e,
    caseSensitive: false,
    name: "Tr-Po___th"
  }, {
    path: "/Tr/Pos",
    component: _e882a7d0,
    caseSensitive: false,
    name: "Tr-Pos___th"
  }, {
    path: "/auth/login/index_bak",
    component: _3ce7811a,
    caseSensitive: false,
    name: "auth-login-index_bak___th"
  }, {
    path: "/en/auth/login",
    component: _1a4b3266,
    caseSensitive: false,
    name: "auth-login___en"
  }, {
    path: "/en/Db/Brand",
    component: _2617ea85,
    caseSensitive: false,
    name: "Db-Brand___en"
  }, {
    path: "/en/Db/Category",
    component: _72f5032c,
    caseSensitive: false,
    name: "Db-Category___en"
  }, {
    path: "/en/Db/Department",
    component: _c1ecb7d4,
    caseSensitive: false,
    name: "Db-Department___en"
  }, {
    path: "/en/Db/Expen",
    component: _0c460d26,
    caseSensitive: false,
    name: "Db-Expen___en"
  }, {
    path: "/en/Db/Products",
    component: _59206378,
    caseSensitive: false,
    name: "Db-Products___en"
  }, {
    path: "/en/Db/Supplier",
    component: _e27c5648,
    caseSensitive: false,
    name: "Db-Supplier___en"
  }, {
    path: "/en/Db/Um",
    component: _6018cf10,
    caseSensitive: false,
    name: "Db-Um___en"
  }, {
    path: "/en/Report/R_Adjstock",
    component: _ac0e84d4,
    caseSensitive: false,
    name: "Report-R_Adjstock___en"
  }, {
    path: "/en/Report/R_Cashmove",
    component: _2cdbd3c1,
    caseSensitive: false,
    name: "Report-R_Cashmove___en"
  }, {
    path: "/en/Report/R_Instock",
    component: _60aef4fe,
    caseSensitive: false,
    name: "Report-R_Instock___en"
  }, {
    path: "/en/Report/R_Onhand",
    component: _87b6ca12,
    caseSensitive: false,
    name: "Report-R_Onhand___en"
  }, {
    path: "/en/Report/R_Po",
    component: _0cc4beb4,
    caseSensitive: false,
    name: "Report-R_Po___en"
  }, {
    path: "/en/Report/R_Pos",
    component: _5b66ee5b,
    caseSensitive: false,
    name: "Report-R_Pos___en"
  }, {
    path: "/en/Report/R_Tr_bill",
    component: _43e27c87,
    caseSensitive: false,
    name: "Report-R_Tr_bill___en"
  }, {
    path: "/en/Sys/Company",
    component: _1cfce654,
    caseSensitive: false,
    name: "Sys-Company___en"
  }, {
    path: "/en/Sys/Shortcut",
    component: _f8f09d0a,
    caseSensitive: false,
    name: "Sys-Shortcut___en"
  }, {
    path: "/en/Tr/Adjstock",
    component: _4319e40e,
    caseSensitive: false,
    name: "Tr-Adjstock___en"
  }, {
    path: "/en/Tr/Cashmove",
    component: _61562424,
    caseSensitive: false,
    name: "Tr-Cashmove___en"
  }, {
    path: "/en/Tr/Expen",
    component: _61098f26,
    caseSensitive: false,
    name: "Tr-Expen___en"
  }, {
    path: "/en/Tr/Instock",
    component: _0170e78a,
    caseSensitive: false,
    name: "Tr-Instock___en"
  }, {
    path: "/en/Tr/Po",
    component: _2aae916e,
    caseSensitive: false,
    name: "Tr-Po___en"
  }, {
    path: "/en/Tr/Pos",
    component: _e882a7d0,
    caseSensitive: false,
    name: "Tr-Pos___en"
  }, {
    path: "/Tr/Instock/bak",
    component: _279ff2b5,
    caseSensitive: false,
    name: "Tr-Instock-bak___th"
  }, {
    path: "/en/auth/login/index_bak",
    component: _3ce7811a,
    caseSensitive: false,
    name: "auth-login-index_bak___en"
  }, {
    path: "/en/Tr/Instock/bak",
    component: _279ff2b5,
    caseSensitive: false,
    name: "Tr-Instock-bak___en"
  }, {
    path: "/",
    component: _2ee36fb4,
    caseSensitive: false,
    name: "index___th"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
