import pdfMake from 'pdfmake/build/pdfmake'
import vfsFonts from 'pdfmake/build/vfs_fonts'
import pdfFonts from '~/assets/custom-fonts.js' // 1. import custom fonts

pdfMake.vfs = vfsFonts.pdfMake.vfs
pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  },
  // Kanit Font
  Sarabun: { // 3. set Kanit font
    normal: 'Sarabun-Regular.ttf',
    bold: 'Sarabun-Medium.ttf',
    italics: 'Sarabun-Italic.ttf',
    bolditalics: 'Sarabun-MediumItalic.ttf'
  },
  Tahoma: { // 3. set Kanit font
    normal: 'Tahoma-Regular.ttf',
    bold: 'Tahoma--Medium.ttf',
    // italics: 'Sarabun-Italic.ttf',
    // bolditalics: 'Sarabun-MediumItalic.ttf'
  },
}


export default ({ app }, inject) => {
  inject('pdfMake', pdfMake)
}
